<template>
  <header-line></header-line>
  <div class="content" v-if="!loading" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <div class="vouchers__tabs">
        <ul class="tabs__menu">
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'home', params: {lang}}">{{ t('label.home') }}</router-link>
          </li>
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'shop', params: {lang}}">{{ t('label.shop') }}</router-link>
          </li>
          <li class="tabs__item ui-tabs-active">
            <router-link class="tabs__link" v-bind:to="{name: 'about', params: {lang}}">{{ t('label.about') }}</router-link>
          </li>
        </ul>
      </div>

      <article class="page page--article">
        <div class="article__content richtext" v-html="page.content"></div>
        <div class="contact-block">
          <div class="contact-form" v-if="page.canContact && !contactForm.loading && !contactForm.success">
            <div class="form__title">{{ t('label.contact_us') }}</div>
            <form action="?" @submit.prevent="contactFormSubmit">
              <div class="form__row" id="cnameContainer" v-bind:class="{'error': isFieldInvalid('name')}">
                <label class="form__label" for="cname">{{ t('label.name') }}</label>
                <input class="form__input" type="text" name="cname" id="cname"
                       v-model="contactForm.name" v-on:keyup="clearErrors('name')"
                >
                <div class="error" v-if="isFieldInvalid('name')">{{ t(getFieldError('name')) }}</div>
              </div>

              <div class="form__row" id="emailContainer" v-bind:class="{'error': isFieldInvalid('email')}">
                <label class="form__label" for="email">{{ t('label.email') }}</label>
                <input class="form__input" type="email" name="email" id="email"
                       v-model="contactForm.email" v-on:keyup="clearErrors('email')"
                >
                <div class="error" v-if="isFieldInvalid('email')">{{ t(getFieldError('email')) }}</div>
              </div>

              <div class="form__row" id="messageContainer" v-bind:class="{'error': isFieldInvalid('message')}">
                <label class="form__label" for="message">{{ t('label.message') }}</label>
                <textarea class="form__input" name="message" id="message"
                          v-model="contactForm.message" v-on:keyup="clearErrors('message')"
                />

                <div class="error" v-if="isFieldInvalid('message')">{{ t(getFieldError('message')) }}</div>
              </div>

              <div class="form__row--error" v-if="contactForm.success === false">{{ t('label.contact_us_error') }}</div>

              <div class="captcha-container">
                <vue-recaptcha
                  theme="light"
                  size="normal"
                  tabindex="0"
                  @widgetId="recaptchaWidget = $event"
                  @verify="callbackVerify($event)"
                  @fail="callbackFail"
                  @expired="callbackExpired"
                />
              </div>
              <div class="captcha-container">
                <div class="form__row--error" v-if="isFieldInvalid('recaptchaToken')">
                  {{ t('error.recaptcha_required') }}
                </div>
              </div>

              <div class="form__row form__row--acenter form__row--btns">
                <button class="btns btn-view btn-orange" type="submit">{{ t('label.submit') }}</button>
              </div>
            </form>
          </div>
          <div class="contact-form contact-success" v-else-if="contactForm.success">
            <div class="form__title">{{ t('label.contact_us_thank_you') }}<br>{{ t('label.contact_us_sent_ok') }}</div>
          </div>
          <div class="contact-form" v-else-if="contactForm.loading">
            <Preloader/>
          </div>

          <div class="contact-map">
            <GMapMap
              v-if="page.address"
              :center="{lat: page.address.lat, lng: page.address.lon}"
              :zoom="9"
              map-type-id="terrain"
            >
              <GMapMarker :position="{lat: page.address.lat, lng: page.address.lon}">
                <GMapInfoWindow :closeclick="false">{{ data.name }}</GMapInfoWindow>
              </GMapMarker>
            </GMapMap>
            <div v-if="page.address && page.address.address"
                 class="contact-map-address"
            >{{ page.address.address }}
            </div>
          </div>
        </div>

      </article>
    </div>
  </div>
  <page-loader v-else></page-loader>
  <footer-line></footer-line>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import HeaderLine from "@/components/HeaderLine";
  import FooterLine from "@/components/FooterLine";
  import { useStore } from 'vuex'
  import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
  import { ACTION_TYPES, ERROR_TYPES } from "@/constants";
  import PageLoader from "@/components/PageLoader";
  import RequestHandler from "@apiClient/RequestHandler";
  import Preloader from "@/components/Preloader";
  import { useRecaptcha, VueRecaptcha } from "vue3-recaptcha-v2";

  export default {
    name: 'contact',
    components: { Preloader, PageLoader, FooterLine, VueRecaptcha, HeaderLine },
    setup() {
      const store = useStore();
      const page = computed(() => store.getters.contact);
      const data = computed(() => store.getters.data);
      const loading = ref(false);
      const { t } = useI18n();
      const contactForm = reactive({
        name: '',
        email: '',
        message: '',
        recaptchaToken: '',
        loading: false,
        success: null,
        invalidFields: {},
      });

      const { resetRecaptcha } = useRecaptcha();
      const recaptchaWidget = ref(null);
      const callbackVerify = (response) => contactForm.recaptchaToken = response;
      const callbackExpired = () => {
        contactForm.recaptchaToken = '';
        resetRecaptcha(recaptchaWidget.value);
        console.log("recaptcha expired");
      };
      const callbackFail = () => {
        contactForm.recaptchaToken = '';
        resetRecaptcha(recaptchaWidget.value);
        console.log("recaptcha fail");
      };

      const clearErrors = (field) => contactForm.invalidFields[field] = undefined;
      const isFieldInvalid = (field) => !!contactForm.invalidFields[field];
      const getFieldError = (field) => contactForm.invalidFields[field];

      onMounted(() => {
        if (!page.value && !store.getters.isContactLoading) {
          store.dispatch(ACTION_TYPES.loadContact);
        }
      });

      watchEffect(() => {
        loading.value = !page.value;
      });

      const contactFormSubmit = () => {
        contactForm.invalidFields = {};
        let isInvalid = false;
        ['name', 'email', 'message', 'recaptchaToken'].forEach((field) => {
          if (!contactForm[field]) {
            contactForm.invalidFields[field] = ERROR_TYPES.required;
            isInvalid = true;
          }
        });
        if (contactForm.message && contactForm.message.length > 2000) {
          contactForm.invalidFields.message = ERROR_TYPES.too_long;
          isInvalid = true;
        }

        if (!isInvalid) {
          contactForm.loading = true;
          RequestHandler.submitContactForm(data.value.identifier, {
            name: contactForm.name,
            email: contactForm.email,
            message: contactForm.message,
            recaptchaToken: contactForm.recaptchaToken,
          }).then(() => {
            contactForm.loading = false;
            contactForm.success = true;
          }).catch(() => {
            contactForm.loading = false;
            contactForm.success = false;
          })
        }
      }

      return {
        recaptchaWidget, callbackVerify, callbackFail, callbackExpired,
        t, loading, page, data, contactForm, clearErrors, isFieldInvalid, contactFormSubmit, getFieldError
      };
    }
  }
</script>

<style>
  .vue-map-container {
    width: 100%;
    min-height: 300px;
    height: calc(100% - 75px);
  }

  .contact-map-address {
    text-align: center;
    padding: 10px;
  }

  .contact-success {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .contact-block {
    display: flex;
    align-content: stretch;
    margin: 30px 0;
    flex-direction: row;
  }

  .contact-form, .contact-map {
    width: 50%;
  }

  .contact-form {
    margin-right: 30px;
  }

  .contact-form .form__title {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .contact-form, .contact-map {
      width: 100%;
    }

    .contact-map {
      height: 300px;
      padding-top: 25px;
    }

    .contact-block {
      flex-direction: column;
    }
  }

  .captcha-container {
    display: flex;
    justify-content: center;
  }
</style>